<template>
    <div class="col-span-12 lg:col-span-7 w-full p-6 rounded-xl shadow-md mt-28 md:mt-0">
        <div class="flex flex-wrap items-center justify-between">
            <div class="uppercase font-ffdin font-bold text-gray-450 title">{{ $t('lease.overview') }}</div>

            <!-- <div class="flex items-center">
                <span class="mr-2 font-ffdin uppercase text-gray-450 btnFull font-medium cursor-pointer">{{ $t('lease.view_full') }}</span>
                <div class="relative">
                    <icon
                        name="arrow-left"
                        class="sm:w-5 sm:h-5 ml-2 block cursor-pointer transform rotate-180 text-gray-450"
                    />
                </div>
            </div> -->
        </div>

        <div class="grid grid-cols-2 gap-2 md:gap-1 md:grid-cols-3 lg:grid-cols-4 w-full items-end md:items-center">
            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.occupants') }}</label>
                    <div
                        class="leaseItem_value text-sofia text-gray-700"
                        data-test="occupants"
                    >
                        {{ applicantName }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.email') }}</label>
                    <div
                        class="leaseItem_value text-sofia text-gray-700 truncate"
                        data-test="email"
                    >
                        {{ applicantEmail }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.phone') }}</label>
                    <div
                        class="leaseItem_value truncate text-sofia text-gray-700"
                        data-test="phone"
                    >
                        {{ applicantPhone }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.pets') }}</label>
                    <div
                        class="leaseItem_value truncate text-sofia text-gray-700"
                        data-test="pets"
                    >
                        {{ formattedPets }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.expires') }}</label>
                    <div
                        class="leaseItem_value text-sofia text-gray-700"
                        data-test="lease-expires"
                    >
                        {{ leaseExpires }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.rent_amount') }}</label>
                    <div
                        class="leaseItem_value truncate text-sofia text-gray-700"
                        data-test="rent-amount"
                    >
                        {{ rentAmount }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.co_resident') }}</label>
                    <div
                        class="leaseItem_value text-sofia text-gray-700"
                        data-test="co-resident"
                    >
                        {{ residentUsers }}
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="leaseItem">
                    <label class="text-gray-450 text-xs leaseItem_label">{{ $t('lease.block.other') }}</label>
                    <div
                        class="leaseItem_value text-sofia text-gray-700"
                        data-test="other-occupants"
                    >
                        {{ occupantsUsers }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from '@/mixins/AuthMixin';
import { dateFormatter } from '@/utils/Date';
import { typeUsers } from '@/components/dashboard/constants.js';
import axios from 'axios';
import getEnvVariable from '@/utils/getEnvVariable';

export default {
    name: 'LeaseOverview',

    components: {
        // Icon,
    },

    mixins: [NotifyMixin, AuthMixin],

    data() {
        return {
            occupancy: null,
            applicantPhone: '',
            pets: [],
        };
    },

    computed: {
        users() {
            return this.occupancy?.occupancy_relation || [];
        },

        applicantUser() {
            return this.users.find(
                user => user.relationship_type_name === typeUsers.applicant || user.relationship_type_name === typeUsers.resident
            );
        },

        applicantName() {
            return this.applicantUser?.party?.full_name || '';
        },

        applicantPartyId() {
            return this.applicantUser?.party?.party_id;
        },

        applicantPartyContactId() {
            return this.applicantUser?.party?.contact_id;
        },

        applicantEmail() {
            return this.currentUser?.email;
        },

        rentAmount() {
            const phases = this.occupancy?.phase || [];
            return phases.length > 0 ? phases[phases.length - 1].market_rent_rate_at_start : '';
        },

        leaseExpires() {
            return dateFormatter(this.occupancy?.end_date) || '';
        },

        residentUsers() {
            const residents = this.users.filter(user => user.relationship_type_name === typeUsers.resident) || [];
            if (residents.length === 0 && this.applicantUser) {
                return [this.applicantUser.party.full_name];
            }
            return residents
                .map(resident => resident.party.full_name)
                .filter(
                    residentFullName =>
                        residentFullName.replaceAll(' ', '') !== `${this.currentUser?.firstName}${this.currentUser?.lastName}`
                )
                .join(',');
        },

        occupantsUsers() {
            const occupants = this.users.filter(user => user.relationship_type_name === typeUsers.occupant) || [];
            return occupants.map(resident => resident.party.full_name).join(',');
        },

        formattedPets() {
            if (this.pets.length > 0) {
                return this.pets.map(pet => pet.pet_species).join(', ');
            }
            return 'N/A';
        },
    },

    watch: {
        currentProfile: {
            immediate: true,
            async handler(val) {
                if (val) {
                    await this.getOccupancy();
                    if (this.occupancy && this.applicantPartyContactId) {
                        this.getContact(this.applicantPartyContactId);
                    }
                    if (this.applicantPartyId) {
                        this.getPet(this.applicantPartyId);
                    }
                }
            },
        },
    },

    methods: {
        async getOccupancy() {
            try {
                const data = await this.$leasingServiceDataProvider.getOne('occupancy', {
                    occupancy_id: this.currentProfile.occupancyId,
                });
                this.occupancy = data;
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async getPet(partyId) {
            try {
                const data = await this.$leasingServiceDataProvider.getOneByPartyId('pet', {
                    partyId,
                });
                this.pets = data;
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async getContact(contactId) {
            //TODO: change request when contact service will work properly
            try {
                const baseContactUrl = getEnvVariable('VUE_APP_CONTACT_API_URL');
                const { data } = await axios.get(`${baseContactUrl}contact/${contactId}`);
                this.applicantPhone = data?.phone.length > 0 ? data?.phone[0].phone_number : '';
            } catch (error) {
                this.notifyError(error.message);
            }
        },
    },
};
</script>

<style scoped>
.title {
    font-size: 1.063rem;
    line-height: 1.25rem;
    letter-spacing: 0.043rem;
}
.btnFull {
    font-size: 0.75rem;
    line-height: 0.938rem;
    letter-spacing: 0.038rem;
}
.leaseItem_label {
    letter-spacing: 0.019rem;
    margin-bottom: 0.438rem;
}
.leaseItem_value {
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
}
</style>
